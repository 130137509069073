<template>
    <div
        class="bm-input"
        :class="{
            'has-error': !!errorMessage
        }"
    >
        <div
            class="input"
            :style="{
                width: computedWidth
            }"
        >
            <input
                ref="input"
                name="name"
                :id="name"
                :type="type"
                :value="modelValue"
                :placeholder="placeholder + (required ? '*' : '')"
                @change="handleChange"
            >
                <!-- :value="inputValue"
                @input="handleChange"
                @blur="handleBlur" -->
            <img
                v-if="type === 'password'"
                src="@/assets/icons/Others/eye.svg"
                alt="eye"
                class="icon-eye"
                @click="togglePassword"
            />
        </div>
        <p class="error-message" v-show="errorMessage">
            {{ errorMessage }}
        </p>
    </div>
</template>

<script>
// import { useField } from "vee-validate";

export default {
    name: 'BMInput',
    props: {
        type: {
            default : 'text',
            required: false,
            validator(val) {
                return ['text', 'password'].includes(val)
            }
        },
        modelValue: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
        },
        errorMessage: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            required: false
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        width: {
            type: [Number, String],
            required: false,
            default: '100%'
        }
    },
    emits: ['update:modelValue', 'valid'],
    computed: {
        computedWidth() {
            if (typeof this.width === 'number') {
                return this.width + 'px'
            }
            return this.width 
        }
    },
    methods: {
        togglePassword() {
            if (this.$refs.input.type === 'password') {
                this.$refs.input.type = 'text'
            } else {
                this.$refs.input.type = 'password'
            }
        },
        handleChange(e) {
            this.$emit('update:modelValue', e.target.value)
            this.$emit('change', e.target.value)
        }
    }
}
</script>