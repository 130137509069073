import service from './service';

export function emailExists(email = '', config = {}) {
    const body = {}
    config = {
        params: {
            value: email
        }
    }
    return service.post('/email-exists', body, config)
}

export function login(body = {}, config = {}) {
    return service.post('/login', {}, config)
}

export function loginEmail(body = {}, config = {}) {
    config = {
        params: body
    }
    return service.post('/login/email', {}, config)
}

export function loginPhone(body = {}, config = {}) {
    return service.post('/login/phone', {}, config)
}