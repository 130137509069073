<template>
  <div class="sign-in d-flex flex-row">
    <div class="left d-flex flex-column justify-center">
      <h2>Hello!<span> Welcome back</span></h2>

      <div class="account-create d-flex flex-row pointer">
        <h5>Do not have an account yet?</h5>
        <h4>Sign up</h4>
      </div>
    </div>
    <div class="right d-flex flex-column justify-center">
      <h2>Sign in</h2>
      <div class="form-container d-flex flex-column">
        <form @submit.prevent="onSubmit">
          <BMInput
            type="text"
            name="email"
            placeholder="Email"
            v-model="email"
            :errorMessage="emailError"
          />
          <BMInput
            type="password"
            name="password"
            placeholder="Password"
            v-model="password"
            :errorMessage="passwordError"
          />
          <h4 class="pointer">Forgot password?</h4>
          <Button :width="360" :backGround="'#000'" :color="'#fff'">Sign in</Button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, useField, useForm } from "vee-validate";
import * as Yup from "yup";
import { useRouter } from "vue-router";

import { emailExists, loginEmail } from "@/api"

import Button from "@/components/Button";
import BMInput from "@/components/Form/BMInput";

export default {
  name: "SignIn",
  components: {
    Form,
    Field,
    Button,
    BMInput
  },
  setup() {
    // TODO make it global
    Yup.addMethod(Yup.string, 'checkEmailExists', function () {
      return this.test('checkEmailExists', 'User with such email does not exist', function (value) {
        return checkEmailExists(value)
      });
    });

    const router = useRouter();
    const { handleSubmit, setFieldError } = useForm();
    const { value: email, errorMessage: emailError, meta: emailMeta} = useField(
      'email',
      Yup.string().required("Email is required").email("Email is invalid").checkEmailExists(),  
      {
        initialValue: ''
      }
    );
    const { value: password, errorMessage: passwordError} = useField(
      'password',
      Yup.string().required("Password is required").min(6),
      {
        initialValue: ''
      }
    );

    const onSubmit = handleSubmit ((values, actions) => {
      loginEmail({
        value: values.email,
        password: values.password
      })
        .then(res => {
          if (res.data.error) {
            setFieldError('password', 'Incorrect password')
            actions.setValues({
              password: ""
            });
          } else if (res.data.data) {
            cookieStore.set('token', res.data.data)
            router.push('/')
          }

        })
        .catch((e) => {
          console.log("error", e);
        });
    });

    const emailOnChange = () => {
      if (emailMeta.valid) {
        checkEmailExists
      }
    }

    const checkEmailExists = (email) => {
      return new Promise((resolve, reject) => {
        emailExists(email).then(res => {
          if (!res.data.data) {
            resolve(false)
          }
          resolve(true)
        })
        .catch(() => { reject() })

      })
    }

    return {
      onSubmit,
      emailOnChange,
      email,
      emailError,
      password,
      passwordError
    };
  }
};
</script>

