import axios from 'axios';

const service = axios.create({
    baseURL: 'https://demo-flowers-api.newtribe.nl/api',
    timeout: 5000,
    // headers: {
    //     'Authorization': await window.cookieStore.get('token')?.value
    // }
    withCredentials: false,
    headers: {
        "Access-Control-Allow-Origin": "*"
    }
})

export default service